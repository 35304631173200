import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router";
// nodejs library that concatenates classes
import { UserContext } from "context/context";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DateTimePicker } from "@vaadin/react-components/DateTimePicker.js";
import moment from "moment";
import {
  Chart as ChartJS,
  PointElement,
  Filler,
  LineController,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
// reactstrap components
import { Row, Col, Form, Button } from "reactstrap";

import { SENSORS } from "../constants";
import {
  useGetOneDeviceApiMutation,
  useGetIotDataApiMutation,
} from "state/slices/webSlices"; // Use Rtk-query for API calling
import GraphComponent from "./GraphComponent";
import { setCurrentDeviceID } from "state/slices/webSlices";

// Register the elements, scales, and controllers you are using
ChartJS.register(
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  BarElement,
  Filler,
  Tooltip,
  Legend
);

function Graphs({ ...props }) {
  const [getOneDeviceApi] = useGetOneDeviceApiMutation(); // for api call
  const [getIotDataApi] = useGetIotDataApiMutation(); // for api call

  const currentDeviceID = useSelector(
    (state) => state.currentDevice.currentDeviceID
  ); // Get currentDeviceID from redux state

  const dispatch = useDispatch(); // For set currentDeviceId to redux

  // Use For get data from Chart's json(Data)
  const canvas = document.createElement("canvas");

  // Graph Data
  const [data, setData] = useState([]);
  const [sensors, setSensonrs] = useState([]);

  //State to decide whether useEffect should be executed or not
  const setIntervalVar = null;

  // State to show loading spinner while fetching data and showing error if exists
  const [loadingAndMsg, setLoadingAndMsg] = useState({
    loading: true,
    msg: null,
  });

  // State to set duration of data
  const [duration, setDuration] = useState({
    start: Date.now() - 24 * 60 * 60 * 1000,
    end: Date.now(),
  });

  //hooks
  const { user } = useContext(UserContext);
  let { id } = useParams();
  const [userRole, setUserRole] = useState(0);

  useEffect(() => {
    if (id !== ":id") {
      dispatch(setCurrentDeviceID(id));
    }
  }, [id]);

  useEffect(() => {
    if (id === ":id") {
      id = currentDeviceID;
    }
  }, [currentDeviceID]);

  // use for set role admin(1)/user(0)
  useEffect(() => {
    setUserRole(user.role);
  }, [user]);

  // GetData: fetches sensor data
  async function getData() {
    // Getting all the Devices if no id is passed
    let tempDevices = await getOneDeviceApi({ email: user.email });
    if (id === ":id") {
      id = tempDevices.data.responseDataa[0];
    }

    // const tempSensors = tempDevices.data.responseDataa.find(e => e.device === id).arr;
    const tempSensors = tempDevices?.data?.sensor?.find(
      (e) => e.device === id
    )?.arr;
    if (tempSensors) {
      setSensonrs(tempSensors);
    } else {
      setSensonrs(SENSORS);
    }

    // fetching Sensor Data
    let temp = await getIotDataApi({
      device_id: id.split(":")[1].trim(),
      start: duration.start,
      end: duration.end,
    });

    temp = temp.data.responseDataa.Items;

    // Create a new array with modified objects
    const updatedTemp = temp.map((item) => {
      const date = moment(item?.timestamp).format("YYYY/MM/DD");
      const time = moment(item?.timestamp).format("HH:mm:ss");

      // Return a new object with updated properties
      return {
        ...item,
        payload: {
          ...item.payload,
          time: time,
          date: date,
        },
        timestamp: `${date} ${time} UTC`,
      };
    });

    // Use updatedTemp instead of modifying temp directly
    updatedTemp.sort(function (a, b) {
      // return new Date(a.payload.datetime) - new Date(b.payload.datetime);
      return new Date(a.timestamp) - new Date(b.timestamp);
    });

    setData(updatedTemp); // setting state: data to fetched data
    setLoadingAndMsg({
      // setting loadingAndMsg: to remove loading spinner and display error if any
      loading: false,
      msg:
        updatedTemp.length === 0
          ? "Sorry! Device is not active or Invalid Input in Date/Time 😔"
          : null,
    });
  }

  // fetch sensor data when change duration
  useEffect(() => {
    getData();
  }, [duration]);

  // Get the duration from user input (on form submit) and change the duration state
  function setStartAndEnd(e) {
    e.preventDefault();

    // Taking user inputs
    let start = document.getElementById("startTime").value;
    let end = document.getElementById("endTime").value;

    if (start === "" && end === "") {
      return;
    }

    // Converting to EPOCH time format
    let startTime = new Date(start).getTime();
    let endTime = new Date(end).getTime();

    if (Number.isNaN(startTime)) {
      startTime = endTime - 24 * 60 * 60 * 1000;
    }

    if (Number.isNaN(endTime)) {
      endTime = startTime + 24 * 60 * 60 * 1000;
    }

    // If start time is earlier than end time
    if (startTime > endTime) {
      setLoadingAndMsg({
        loading: false,
        msg: "Invalid duration!  Start time should be earlier than End Time 😕",
      });
    } else {
      clearInterval(setIntervalVar);
      setDuration({
        start: startTime,
        end: endTime,
      });
    }
  }

  // Time
  function getTime() {
    let arrTime = [];

    for (let i = 0; i < data.length; i++) {
      let tempData = data[i].payload.time;

      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }
      var initial = data[i].payload.date.split(/\//);
      var convertedTime = [initial[2], initial[1], initial[0]].join("/");

      tempData = tempData
        .split("/")
        .map((e) => {
          if (e.length === 1) return "0" + e;
          else return e;
        })
        .join(":");
      tempData = convertedTime + " - " + tempData;

      arrTime.push(tempData);
    }
    return arrTime;
  }

  //Weight1
  //new: sensor_HX711_1
  //new: sensor_HX711_2

  //NTC_1-- > Temperature 1
  //NTC_2-- > Temperature 2
  //NTC_3-- > Temperature 3

  //SHT_1-- > Temperature + Humidity 1
  //SHT_2-- > Temperature + Humidity 2
  //SHT_3-- > Temperature + Humidity 3

  //NTC is an acronym for Negative Temperature Coefficient

  // Functions to get the time, temp & hum, temp and weight from data

  //Temp and Humidity 1
  //Old: sensor_DHT_1, sensor_DHT_2
  //New: sensor_SHT_1, sensor_SHT_2, sensor_SHT_3
  function getTempAndHum1() {
    let arrTemp = [];
    let arrHum = [];
    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      const tempData = data[i]?.payload?.sensor_DHT_1
        ? data[i]?.payload?.sensor_DHT_1[0]
        : data[i].payload.sensor_SHT_1[0];
      const humData = data[i]?.payload?.sensor_DHT_1
        ? data[i]?.payload?.sensor_DHT_1[1]
        : data[i].payload.sensor_SHT_1[1];
      arrTemp.push(tempData);
      arrHum.push(humData);
    }
    return { arrTemp, arrHum };
  }

  //Temp and Humidity 2
  function getTempAndHum2() {
    let arrTemp = [];
    let arrHum = [];
    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }
      const tempData = data[i]?.payload?.sensor_DHT_2
        ? data[i]?.payload?.sensor_DHT_2[0]
        : data[i].payload.sensor_SHT_2[0];
      const humData = data[i]?.payload?.sensor_DHT_2
        ? data[i]?.payload?.sensor_DHT_2[1]
        : data[i].payload.sensor_SHT_2[1];
      arrTemp.push(tempData);
      arrHum.push(humData);
    }
    return { arrTemp, arrHum };
  }

  //Temp and Humidity 3
  function getTempAndHum3() {
    let arrTemp = [];
    let arrHum = [];
    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      if (data[i].payload.sensor_SHT_3) {
        const tempData = data[i]?.payload?.sensor_SHT_3[0];
        const humData = data[i]?.payload?.sensor_SHT_3[1];
        arrTemp.push(tempData);
        arrHum.push(humData);
      }
    }
    return { arrTemp, arrHum };
  }

  //Old: sensor_DS18B20_1, sensor_DS18B20_2
  //New: sensor_NTC_1, sensor_NTC_2, sensor_NTC_3
  //Temperature

  //OLD: DS18B20_1 & DS18B20_2
  function getTemperatureDS() {
    let arrTemp1 = [];
    let arrTemp2 = [];
    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      if (
        data[i].payload.sensor_DS18B20_1 &&
        data[i].payload.sensor_DS18B20_2
      ) {
        const tempData1 = data[i].payload.sensor_DS18B20_1[0];
        const tempData2 = data[i].payload.sensor_DS18B20_2[0];
        arrTemp1.push(tempData1);
        arrTemp2.push(tempData2);
      }
    }
    return { arrTemp1, arrTemp2 };
  }

  function getAllTemperature() {
    let arrTemp1 = [];
    let arrTemp2 = [];
    let arrTemp3 = [];
    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      const tempData1 = data[i]?.payload?.sensor_NTC_1;
      const tempData2 = data[i]?.payload?.sensor_NTC_2;
      const tempData3 = data[i]?.payload?.sensor_NTC_3;
      arrTemp1.push(tempData1);
      arrTemp2.push(tempData2);
      arrTemp3.push(tempData3);
    }
    return { arrTemp1, arrTemp2, arrTemp3 };
  }

  //Old: sensor_HX711
  //New: sensor_HX711_1, sensor_HX711_2
  //Weight
  function getWeight1() {
    let arrWeight = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }
      const tempData =
        data[i]?.payload?.sensor_HX711 ?? data[i]?.payload?.sensor_HX711_1;
      arrWeight.push(tempData);
    }
    return arrWeight;
  }

  function getWeight2() {
    let arrWeight = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }
      const tempData = data[i]?.payload?.sensor_HX711_2;
      arrWeight.push(tempData);
    }
    return arrWeight;
  }

  // Battery Voltage
  //Old: battery
  //New: battery
  function getBattery() {
    let arrBattery = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      const tempData =
        data[i].payload.battery?.voltage ?? data[i].payload.battery;
      arrBattery.push(tempData);
    }
    return arrBattery;
  }

  //OLD: signal_strenght - rssi
  function getRSSI() {
    let arrRssi = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      if (data[i].payload.signal_strength_rssi) {
        const tempData = data[i].payload.signal_strength_rssi[0];
        arrRssi.push(tempData);
      } else if (data[i].payload.signal_strength) {
        const tempData = data[i]?.payload?.signal_strength[0];
        arrRssi.push(tempData);
      } else {
        arrRssi.push("0");
      }

      // const tempData = data[i]?.payload?.signal_strength[0] ?? data[i]?.payload?.signal_strength_rssi[0];
      // arrRssi.push(tempData);
    }
    return arrRssi;
  }

  //OLD: signal_strenght - rsrp
  function getRSRP() {
    let arrRsrp = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      if (data[i].payload.signal_strength_rsrp) {
        const tempData = data[i].payload.signal_strength_rsrp[0];
        arrRsrp.push(tempData);
      } else if (data[i].payload.signal_strength) {
        const tempData = data[i]?.payload?.signal_strength[1];
        arrRsrp.push(tempData);
      } else {
        arrRsrp.push("0");
      }

      // if (data[i].payload.signal_strength_rsrp) {
      //   const tempData = data[i].payload.signal_strength_rsrp[0];
      //   const tempData = data[i].payload.signal_strength_rsrp[0] ?? data[i].payload.signal_strength[1];
      //   arrRsrp.push(tempData);
      // }
    }
    return arrRsrp;
  }

  //OLD: signal_strenght - sinr
  function getSINR() {
    let arrSinr = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      // if (data[i].payload.signal_strength_sinr) {
      //   const tempData = data[i].payload.signal_strength_sinr[0];
      //   // const tempData = data[i].payload.signal_strength_sinr[0] ?? data[i].payload.signal_strength[2];
      //   arrSinr.push(tempData);
      // }

      if (data[i].payload.signal_strength_sinr) {
        const tempData = data[i].payload.signal_strength_sinr[0];
        arrSinr.push(tempData);
      } else if (data[i].payload.signal_strength) {
        const tempData = data[i]?.payload?.signal_strength[2];
        arrSinr.push(tempData);
      } else {
        arrSinr.push("0");
      }
    }
    return arrSinr;
  }

  //OLD: signal_strenght - rsrq
  function getRSRQ() {
    let arrRsrq = [];

    for (let i = 0; i < data.length; i++) {
      if (
        new Date(data[i].payload.date).getTime() < duration.start ||
        new Date(data[i].payload.date).getTime() > duration.end
      ) {
        continue;
      }

      // if (data[i].payload.signal_strength_rsrq) {
      //   const tempData = data[i].payload.signal_strength_rsrq[0];
      //   arrRsrq.push(tempData);
      // }

      if (data[i].payload.signal_strength_rsrq) {
        const tempData = data[i].payload.signal_strength_rsrq[0];
        arrRsrq.push(tempData);
      } else if (data[i].payload.signal_strength) {
        const tempData = data[i]?.payload?.signal_strength[3];
        arrRsrq.push(tempData);
      } else {
        arrRsrq.push("0");
      }

      // const tempData = data[i].payload.signal_strength_rsrq[0] ?? data[i].payload.signal_strength[3];
      // arrRsrq.push(tempData);
    }
    return arrRsrq;
  }

  // Chart Options
  let chart1_2_options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        backgroundColor: "#f5f5f5",
        titleColor: "#333",
        bodyColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
    },
    scales: {
      y: {
        barPercentage: 1.6,
        grid: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent",
        },
        ticks: {
          min: -100, // 'suggestedMin' is now 'min'
          max: 100, // 'suggestedMax' is now 'max'
          padding: 5,
          color: "#9a9a9a", // 'fontColor' is now 'color'
        },
        title: {
          display: true,
          text: "Temperature(ºC) & Humidity(%)", // 'scaleLabel.labelString' is now 'title.text'
        },
        position: "left", // Keep the axis position
      },
      x: {
        barPercentage: 1.6,
        grid: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          color: "#9a9a9a", // 'fontColor' is now 'color'
        },
        title: {
          display: true,
          text: "Time", // 'scaleLabel.labelString' is now 'title.text'
        },
      },
    },
  };

  // Temp & Humidity VS Time: Chart 1
  const T1H1VsTime = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "Temperature(ºC)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum1().arrTemp,
          },
          {
            label: "Humidity(%)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#d048b6",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#d048b6",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum1().arrHum,
          },
        ],
      };
    },
    options: chart1_2_options,
  };
  const chartDataT1H1VsTime = T1H1VsTime.data(canvas);

  // Temp & Humidity VS Time: Chart 2
  const T2H2VsTime = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "Temperature(ºC)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum2().arrTemp,
          },
          {
            label: "Humidity(%)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#d048b6",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#d048b6",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum2().arrHum,
          },
        ],
      };
    },
    options: chart1_2_options,
  };
  const chartDataT2H2VsTime = T2H2VsTime.data(canvas);

  // Temp & Humidity VS Time: Chart 3
  const T3H3VsTime = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "Temperature(ºC)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum3().arrTemp,
          },
          {
            label: "Humidity(%)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#d048b6",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#d048b6",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTempAndHum3().arrHum,
          },
        ],
      };
    },
    options: chart1_2_options,
  };
  const chartDataT3H3VsTime = T3H3VsTime.data(canvas);

  // Weight VS Time : Weight Chart 1
  const Weight1 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
      gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
      gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "Weight(Kg)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#00d6b4",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00d6b4",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00d6b4",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getWeight1(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          backgroundColor: "#f5f5f5",
          titleColor: "#333",
          bodyColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
      },
      responsive: true,
      scales: {
        y: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            min: -100, // 'suggestedMin' is now 'min'
            max: 100, // 'suggestedMax' is now 'max'
            padding: 0.001,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Weight(Kg)", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
        x: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(0,242,195,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Time", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
      },
    },
  };
  const chartDataWeight1 = Weight1.data(canvas);

  // Weight VS Time : Weight Chart 2
  const Weight2 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
      gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
      gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "Weight(Kg)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#00d6b4",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00d6b4",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00d6b4",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getWeight2(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          backgroundColor: "#f5f5f5",
          titleColor: "#333",
          bodyColor: "#666",
          bodySpacing: 4,
          padding: 12,
          mode: "nearest",
          intersect: false,
          position: "nearest",
        },
      },
      responsive: true,
      scales: {
        y: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            min: -100, // 'suggestedMin' is now 'min'
            max: 100, // 'suggestedMax' is now 'max'
            padding: 0.001,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Weight(Kg)", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
        x: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(0,242,195,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Time", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
      },
    },
  };
  const chartDataWeight2 = Weight2.data(canvas);

  //OLD: Temperature 1 & 2 VS Time : Temperature Chart
  const chart5 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "DS18B20_1",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTemperatureDS().arrTemp1,
          },
          {
            label: "DS18B20_2",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#d048b6",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#d048b6",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getTemperatureDS().arrTemp2,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          backgroundColor: "#f5f5f5",
          titleColor: "#333",
          bodyColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
      },
      scales: {
        y: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            min: -100, // 'suggestedMin' is now 'min'
            max: 100, // 'suggestedMax' is now 'max'
            padding: 5,
            color: "#9a9a9a", // 'fontColor' is now 'color'
          },
          position: "left",
          title: {
            display: true,
            text: "Temperature(ºC)", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
        x: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            color: "#9a9a9a", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Time", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
      },
    },
  };
  const chartDatachart5 = chart5.data(canvas);

  //New: Temperature 1 & 2 & 3 VS Time : Temperature Chart 1
  const TempNTC = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "NTC_1",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getAllTemperature().arrTemp1,
          },
          {
            label: "NTC_2",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#d048b6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#d048b6",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#d048b6",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getAllTemperature().arrTemp2,
          },
          {
            label: "NTC_3",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#00d6b4",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00d6b4",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00d6b4",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getAllTemperature().arrTemp3,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          backgroundColor: "#f5f5f5",
          titleColor: "#333",
          bodyColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
      },
      scales: {
        y: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            min: -100,
            max: 100,
            padding: 5,
            color: "#9a9a9a",
          },
          title: {
            display: true,
            text: "Temperature(ºC)",
          },
        },
        x: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            color: "#9a9a9a",
          },
          title: {
            display: true,
            text: "Time",
          },
        },
      },
    },
  };
  const chartDataTempNTC = TempNTC.data(canvas);

  // Chart Data 4: Battery Voltage VS Time Only for admin users
  const chart4 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
      gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
      gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "Battery Voltage(V)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#ab00d6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#ab00d6",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#ab00d6",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getBattery(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          backgroundColor: "#f5f5f5",
          titleColor: "#333",
          bodyColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
      },
      responsive: true,
      scales: {
        y: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            min: -100, // 'suggestedMin' is now 'min'
            max: 100, // 'suggestedMax' is now 'max'
            padding: 0.001,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Battery Voltage(V)", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
        x: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(0,242,195,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Time", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
      },
    },
  };
  const chartDatachart4 = chart4.data(canvas);

  // Chart Data 6: RSSI VS Time Only for admin users
  const chart6 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
      gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
      gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "RSSI(dBm)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#ab00d6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#ab00d6",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#ab00d6",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getRSSI(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          backgroundColor: "#f5f5f5",
          titleColor: "#333",
          bodyColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
      },
      responsive: true,
      scales: {
        y: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            min: -150, // 'suggestedMin' is now 'min'
            max: 10, // 'suggestedMax' is now 'max'
            padding: 0.001,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Battery Voltage(V)", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
        x: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(0,242,195,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Time", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
      },
    },
  };
  const chartDatachart6 = chart6.data(canvas);

  // Chart Data 7: RSRP VS Time Only for admin users
  const chart7 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
      gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
      gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "RSRP(dBm)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#00D6B4",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#00D6B4",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#00D6B4",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getRSRP(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          backgroundColor: "#f5f5f5",
          titleColor: "#333",
          bodyColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
      },
      responsive: true,
      scales: {
        y: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            min: -100, // 'suggestedMin' is now 'min'
            max: 100, // 'suggestedMax' is now 'max'
            padding: 0.001,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Battery Voltage(V)", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
        x: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(0,242,195,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Time", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
      },
    },
  };
  const chartDatachart7 = chart7.data(canvas);

  // Chart Data 8: SINR VS Time Only for admin users
  const chart8 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
      gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
      gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "SINR(dB)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1F8EF1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1F8EF1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1F8EF1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getSINR(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          backgroundColor: "#f5f5f5",
          titleColor: "#333",
          bodyColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
      },
      responsive: true,
      scales: {
        y: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            min: -100, // 'suggestedMin' is now 'min'
            max: 100, // 'suggestedMax' is now 'max'
            padding: 0.001,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Battery Voltage(V)", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
        x: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            color: "rgba(0,242,195,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Time", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
      },
    },
  };
  const chartDatachart8 = chart8.data(canvas);

  // Chart Data 9: RSRQ VS Time Only for admin users
  const chart9 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
      gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
      gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors

      return {
        labels: getTime(),
        datasets: [
          {
            label: "RSRQ(dB)",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#ab00d6",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#ab00d6",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#ab00d6",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: getRSRQ(),
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          backgroundColor: "#f5f5f5",
          titleColor: "#333",
          bodyColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest",
        },
      },
      responsive: true,
      scales: {
        y: {
          grid: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            min: -100, // 'suggestedMin' is now 'min'
            max: 100, // 'suggestedMax' is now 'max'
            padding: 0.001,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Battery Voltage(V)", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
        x: {
          grid: {
            drawBorder: false,
            color: "rgba(0,242,195,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            color: "#9e9e9e", // 'fontColor' is now 'color'
          },
          title: {
            display: true,
            text: "Time", // 'scaleLabel.labelString' is now 'title.text'
          },
        },
      },
    },
  };
  const chartDatachart9 = chart9.data(canvas);

  const { loading, msg } = loadingAndMsg;

  return (
    <>
      <div className="content">
        <Row>
          {/* About Device Info */}
          <Col xs="12">
            <h1>
              {id === ":id" ? (
                "Last Device You Viewed"
              ) : (
                <>
                  <strong style={{ color: "rgb(0, 214, 180)" }}>
                    {id.split(":")[0].trim()}:{" "}
                  </strong>{" "}
                  {id.split(":")[1].trim()}
                </>
              )}
            </h1>
          </Col>
          {/* Link for visit Devices */}
          <Col xs="12">
            <h4>
              To select another device visit{" "}
              <Link to={`/${userRole === "1" ? "admin" : "user"}/devices`}>
                Devices
              </Link>
            </h4>
          </Col>
          {/* Link for visit Analytics */}
          {/* {userRole === "1" && ( */}
          {
            <Col xs="12">
              <h4>
                To view Analytics visit{" "}
                <Link
                  to={`/${userRole === "1" ? "admin" : "user"}/analytics/${id}`}
                >
                  Analytics
                </Link>
              </h4>
            </Col>
          }
          {/* Sensors List */}
          <Col xs="12">
            <span
              className="mr-3"
              style={{ color: "#CDC9D1", marginRight: "1rem" }}
            >
              Sensor List:
            </span>
            {sensors?.map((e, index) => (
              <span
                key={index}
                className="mr-3"
                style={{ color: "#BA54F5", marginRight: "1rem" }}
              >
                {e}
              </span>
            ))}
          </Col>
        </Row>

        {/* Duration: start and end time selection */}
        <Form onSubmit={(event) => setStartAndEnd(event)}>
          <Row>
            <Col sm="5">
              <DateTimePicker
                theme="align-right small helper-above-field"
                id="startTime"
                name="startTime"
                timePlaceholder="Start Time"
                datePlaceholder="Start Date"
                label="From"
                style={{
                  maxWidth: "100%",
                  backgroundColor: "#B5D6FF",
                  borderRadius: "10px",
                  margin: "1rem",
                  padding: "0.6rem",
                }}
              />
            </Col>
            <Col sm="5">
              <DateTimePicker
                theme="align-right small helper-above-field"
                id="endTime"
                name="endTime"
                timePlaceholder="End Time"
                datePlaceholder="End Date"
                label="To"
                style={{
                  maxWidth: "100%",
                  backgroundColor: "#B5D6FF",
                  borderRadius: "10px",
                  margin: "1rem",
                  padding: "0.6rem",
                }}
              />
            </Col>
            <Col sm="10">
              <Button
                style={{ width: "100%", margin: "1rem" }}
                type="submit"
                color="success"
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Form>

        {/* Chart 1: Temp and Humidity vs Time */}
        {(sensors.includes("DHT_1") || sensors.includes("SHT_1")) && (
          <>
            <GraphComponent
              graphName={
                <>
                  Box 1 Inside : Temperature{" "}
                  <strong style={{ color: "cyan" }}>&</strong> Humidity{" "}
                  <strong style={{ color: "cyan" }}>VS</strong> Time
                </>
              }
              loading={loading}
              data={chartDataT1H1VsTime}
              options={T1H1VsTime.options}
              msg={msg}
            />
          </>
        )}

        {/* Chart 2: Temp and Humidity vs time */}
        {(sensors.includes("DHT_2") || sensors.includes("SHT_2")) && (
          <>
            <GraphComponent
              graphName={
                <>
                  Box 2 Inside : Temperature{" "}
                  <strong style={{ color: "#D048B6" }}>&</strong> Humidity{" "}
                  <strong style={{ color: "#D048B6" }}>VS</strong> Time
                </>
              }
              loading={loading}
              data={chartDataT2H2VsTime}
              options={T2H2VsTime.options}
              msg={msg}
            />
          </>
        )}

        {/* Chart 3: Temp and Humidity vs time */}
        {sensors.includes("SHT_3") && (
          <>
            <GraphComponent
              graphName={
                <>
                  Outside : Temperature{" "}
                  <strong style={{ color: "#D048B6" }}>&</strong> Humidity{" "}
                  <strong style={{ color: "#D048B6" }}>VS</strong> Time
                </>
              }
              loading={loading}
              data={chartDataT3H3VsTime}
              options={T3H3VsTime.options}
              msg={msg}
            />
          </>
        )}

        {/* Chart 4: Weight vs time */}
        {(sensors.includes("HX711") || sensors.includes("HX711_1")) && (
          <>
            <GraphComponent
              graphName={
                <>
                  {" "}
                  Box 1 : Weight{" "}
                  <strong style={{ color: "#00D6B4" }}>VS</strong> Time
                </>
              }
              loading={loading}
              data={chartDataWeight1}
              options={Weight1.options}
              msg={msg}
            />
          </>
        )}

        {/* Chart 5: Weight vs time */}
        {sensors.includes("HX711_2") && (
          <>
            <GraphComponent
              graphName={
                <>
                  Box 2 : Weight{" "}
                  <strong style={{ color: "#00D6B4" }}>VS</strong> Time
                </>
              }
              loading={loading}
              data={chartDataWeight2}
              options={Weight2.options}
              msg={msg}
            />
          </>
        )}

        {/* Chart 6: Temp1 and Temp 2 vs time */}
        {sensors.includes("DS18B20_1") && sensors.includes("DS18B20_2") && (
          <>
            <GraphComponent
              graphName={
                <>
                  Temperature 1 <strong style={{ color: "cyan" }}>&</strong> 2{" "}
                  <strong style={{ color: "cyan" }}>VS</strong> Time
                </>
              }
              loading={loading}
              data={chartDatachart5}
              options={chart5.options}
              msg={msg}
            />
          </>
        )}

        {/* Chart 6: Temp1 and Temp 2 and Temp 3 vs time */}
        {sensors.includes("NTC_1") &&
          sensors.includes("NTC_2") &&
          sensors.includes("NTC_3") && (
            <>
              <GraphComponent
                graphName={
                  <>
                    Temperature 1 <strong style={{ color: "cyan" }}>&</strong> 2{" "}
                    <strong style={{ color: "cyan" }}>&</strong> 3{" "}
                    <strong style={{ color: "cyan" }}>VS</strong> Time
                  </>
                }
                loading={loading}
                data={chartDataTempNTC}
                options={TempNTC.options}
                msg={msg}
              />
            </>
          )}

        {/* Charts only for Admin */}
        {user.role === "1" ? (
          <>
            {/* Chart 4: Battery Voltage vs time */}
            <GraphComponent
              graphName={
                <>
                  Battery Voltage{" "}
                  <strong style={{ color: "#ab00d6" }}>VS</strong> Time
                </>
              }
              loading={loading}
              data={chartDatachart4}
              options={chart4.options}
              msg={msg}
            />

            {/* Chart 6: RSSI vs time */}
            <GraphComponent
              graphName={
                <>
                  RSSI <strong style={{ color: "#ab00d6" }}>VS</strong> Time
                </>
              }
              loading={loading}
              data={chartDatachart6}
              options={chart6.options}
              msg={msg}
            />

            {/* Chart 7: RSRP vs time */}
            <GraphComponent
              graphName={
                <>
                  RSRP <strong style={{ color: "#00D6B4" }}>VS</strong> Time
                </>
              }
              loading={loading}
              data={chartDatachart7}
              options={chart7.options}
              msg={msg}
            />

            {/* Chart 8: SINR vs time */}
            <GraphComponent
              graphName={
                <>
                  SINR <strong style={{ color: "#1F8EF1" }}>VS</strong> Time
                </>
              }
              loading={loading}
              data={chartDatachart8}
              options={chart8.options}
              msg={msg}
            />

            {/* Chart 9: RSRQ vs time */}
            <GraphComponent
              graphName={
                <>
                  RSRQ <strong style={{ color: "#ab00d6" }}>VS</strong> Time
                </>
              }
              loading={loading}
              data={chartDatachart9}
              options={chart9.options}
              msg={msg}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // } else {
  //   return <Redirect to='login' />
  // }
}

export default Graphs;
