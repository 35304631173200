import React, { useContext } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom"; // Updated imports

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { UserContext } from "../../context/context";
import { useSelector } from "react-redux"; // For get values from redux which was stored

import routes from "routes.js";

import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

function Admin({ ...props }) {
  const { user } = useContext(UserContext);

  const currentDeviceID = useSelector((state) => state.currentDeviceID); // Get currentDeviceID from redux state

  const array = routes.filter((prop) => prop.layout === "/admin");
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );

  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };

  const getRoutes = (array) => {
    return (
      <>
        {array.map((prop, key) =>
          prop.layout === "/admin" ? (
            <Route path={prop.path} element={prop.element} key={key} />
          ) : null
        )}
      </>
    );
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  if (user.role === "1") {
    return (
      <BackgroundColorContext.Consumer>
        {({ color, changeColor }) => (
          <React.Fragment>
            <div className="wrapper">
              <Sidebar
                routes={array}
                logo={{
                  outterLink: "",
                  text: "Ace Cloud",
                  imgSrc: logo,
                  innerLink: "",
                }}
                toggleSidebar={toggleSidebar}
              />
              <div className="main-panel" ref={mainPanelRef} data={color}>
                <AdminNavbar
                  brandText={getBrandText(location.pathname)}
                  toggleSidebar={toggleSidebar}
                  sidebarOpened={sidebarOpened}
                />
                <Routes>
                  {getRoutes(array)}
                  <Route
                    path="/*"
                    // element={<Navigate to="/admin/map" replace />}
                    element={<Navigate to="/admin/dashboard" replace />}
                  />
                </Routes>
                {/* {location.pathname === "/admin/maps" ? null : <Footer fluid />} */}
                {location.pathname === "/admin/dashboard" ? null : (
                  <Footer fluid />
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </BackgroundColorContext.Consumer>
    );
  } else {
    return <Navigate to="/login" />;
  }
}

export default Admin;
